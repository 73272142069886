import styles from './ContactUs.module.scss';

const contactOptions = [
	{
		title: 'United Kingdom',
		address: ['Anephenix Ltd', '160 City Road', 'London', 'EC1V 2NX'],
		companyNumber: '08159564',
		companyNumberUrl: 'https://beta.companieshouse.gov.uk/company/08159564',
	},
	{
		title: 'Estonia',
		address: ['Anephenix OÜ', 'Sepapaja 6', 'Tallinn', '11415'],
		companyNumber: '14612132',
		companyNumberUrl: 'https://creditreports.ee/anephenix-ou',
	},
	{
		title: 'Online',
		address: null,
		socialOptions: [
			{
				iconUrl: '/icons/envelope.svg',
				text: 'Email',
				url: 'mailto:hello@anephenix.com',
			},
			{
				iconUrl: '/icons/github.svg',
				text: 'GitHub',
				url: 'https://github.com/anephenix',
			},
			{
				iconUrl: '/icons/linkedin.svg',
				text: 'LinkedIn',
				url: 'https://www.linkedin.com/company/anephenix',
			},
		],
	},
];

const SocialOption = ({ iconUrl, text, url }, i) => (
	<div className={styles['social-option']} key={i}>
		<a href={url} target="_blank" rel="noreffer noopener">
			{text}
			<img
				loading="lazy"
				width="15"
				height="15"
				src={iconUrl}
				alt={text + ' icon'}
			/>
		</a>
	</div>
);

const ContactOption = (
	{ title, address, socialOptions, companyNumber, companyNumberUrl },
	index
) => {
	let companyDetails = null;
	if (companyNumber)
		companyDetails = (
			<div className={styles['company-number']} key={index}>
				Company number:{' '}
				<a
					href={companyNumberUrl}
					target="_blank"
					rel="noreferrer noopener"
				>
					{companyNumber}
				</a>
			</div>
		);
	return (
		<div className={styles['contact-option']} key={index}>
			<h3>{title}</h3>
			{address && (
				<address>
					{address.map((a, i) => (
						<div key={i}>{a}</div>
					))}
				</address>
			)}
			{socialOptions && <div>{socialOptions.map(SocialOption)}</div>}
			{companyDetails}
		</div>
	);
};

const ContactUs = ({ title }) => (
	<div id={styles['contact-us']}>
		<h2>{title || 'Contact us'}</h2>
		<div id={styles['contact-options']}>
			{contactOptions.map(ContactOption)}
		</div>
	</div>
);

export default ContactUs;
